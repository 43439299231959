import React from 'react';
import { useTranslation } from 'react-i18next';

import SwLogo from '../../images/icon-ws-logo.svg';

const Header = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <header className="header">
      <link href="https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap" rel="stylesheet" />
      <div className="container h-drawer">
        <input className="h-drawer__checkbox" id="drawerCheckbox" type="checkbox" />

        <div className="h-drawer__logo">
          <a href="/">
            <img src={SwLogo} height="40" width="200" alt="" />
          </a>
        </div>
        <label className="h-drawer__overlay" htmlFor="drawerCheckbox" />

        <nav className="h-drawer__menu">
          <ul className="header__menu">
            <li>
              <a href="/guide" className="far fa-smile">
                <span i18next-key="link-ws-guide-text">{t('link-ws-guide-text')}</span>
              </a>
            </li>
            <li>
              <a href={`/categories/`} className="fas fa-shopping-cart">
                <span i18next-key="link-ws-shoplist-text">{t('link-ws-shoplist-text')}</span>
              </a>
            </li>
            <li>
              <a href={`https://${t('link-ws-purchaserequest-url')}`} target="_blank" rel="noopener noreferrer" className="fas fa-list">
                <span i18next-key="link-ws-purchaserequest-text">{t('link-ws-purchaserequest-text')}</span>
              </a>
            </li>
            <li>
              <a href="https://{{link-ws-help-url}}" target="_blank" rel="noopener noreferrer" className="fas fa-question-circle">
                <span i18next-key="link-ws-help-text">{t('link-ws-help-text')}</span>
              </a>
            </li>
          </ul>

          {/*
            <div className="header__entry">
              <a href="#" className="fas fa-boxes"> Order Tracking</a>
              <a href="#" className="fas fa-sign-in-alt">Sign up</a>
            </div>
          */}
        </nav>
        <label className="h-drawer__icon" htmlFor="drawerCheckbox">
          <span className="h-drawer__icon-parts"></span>
        </label>
      </div>
    </header>
  );
};

export default Header;
